<template>
  <div class="page-container-new">
    <page-header title="콘텐츠 생성">
      <template #headerRightSide>
        <button-basic
          text="콘텐츠 목록 보기"
          padding="8px 16px"
          @action="actions.goToContents()"
        ></button-basic>
      </template>
    </page-header>

    <content-form @cancel-content="actions.goToContents()"></content-form>
  </div>
</template>

<script>
import { onBeforeMount, reactive } from "vue";

import { useRouter } from "vue-router";
import ContentForm from "../../../components/console/forms/ContentForm";
import PageHeader from "../../../components/console/headers/PageHeader";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import { useStore } from "vuex";

export default {
  name: "ContentCreate",
  components: {
    ButtonBasic,
    PageHeader,
    ContentForm,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({});

    onBeforeMount(() => {
      resetContent();
    });

    const resetContent = () => {
      store.commit("contents/CONTENT_RESET");
    };

    const isEntPagePath = () => {
      const currentRouter = router.options.history.state.current;
      return currentRouter.includes("/console/ent/contents");
    };

    const actions = {
      goToContents: () => {
        const isEntPage = isEntPagePath();
        if (isEntPage) {
          router.push({ name: "console.ent.contents" });
        } else {
          router.push({ name: "console.contents" });
        }
      },
    };

    return {
      state,
      actions,
    };
  },
};
</script>
