import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46327ad6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-basic" }
const _hoisted_2 = {
  key: 0,
  class: "sub-text-s2"
}
const _hoisted_3 = { class: "sub-text-s2" }
const _hoisted_4 = {
  key: 0,
  class: "text-purple-50"
}
const _hoisted_5 = {
  key: 0,
  class: "sub-text-s3 text-gray-second sub-label"
}
const _hoisted_6 = ["type", "value", "maxlength", "placeholder", "disabled", "min", "max"]
const _hoisted_7 = {
  key: 1,
  class: "info-message"
}
const _hoisted_8 = {
  key: 0,
  class: "caption sub-text-s3 text-gray-third"
}
const _hoisted_9 = {
  key: 1,
  class: "error sub-text-s3 text-red-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString($props.label), 1),
            ($props.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
              : _createCommentVNode("", true)
          ]),
          ($props.subLabel)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("small", null, _toDisplayString($props.subLabel), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: _normalizeClass(["sub-text-s2", { error: !!$props.errorMessage }]),
      type: $props.type,
      style: _normalizeStyle({
        padding: $props.padding,
      }),
      value: $props.defaultValue,
      maxlength: $props.maxLength,
      placeholder: $props.placeholder,
      disabled: $props.disabled,
      min: $props.min,
      max: $props.max,
      onInput: _cache[0] || (_cache[0] = ($event: any) => ($setup.actions.updateData($event)))
    }, null, 46, _hoisted_6),
    (!!$props.errorMessage || !!$props.caption)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (!!$props.caption)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString($props.caption), 1))
            : _createCommentVNode("", true),
          (!!$props.errorMessage)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString($props.errorMessage), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}