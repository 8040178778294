<template>
  <div class="attachment-subtitle-file">
    <!--    todo attachment 파일 체크 로직 수정-->
    <template v-if="state.isAttachment">
      <div class="info">
        <div class="label">
          <span class="sub-text-s2">{{ code }}</span>
        </div>
        <div class="name">
          <a
            style="display: flex"
            class="sub-text-s2"
            target="_blank"
            :href="attachment.url"
            :download="attachment.name"
          >
            {{ attachment.name }}</a
          >
        </div>
        <div class="date">
          <span class="sub-text-s2">{{ createdAt }}</span>
        </div>
        <button-basic
          v-if="showDeleteBtn"
          class="close-btn"
          bg-color="transparent"
          @action="actions.deleteAttachment()"
        >
          <template #icon>
            <close-icon></close-icon>
          </template>
        </button-basic>
      </div>
    </template>
    <template v-if="!state.isAttachment">
      <div class="info">
        <div class="label">
          <span class="sub-text-s2">{{ code }}</span>
        </div>
        <input-radio
          v-model.number="state.fileType"
          :name="`url-${extension}-${index}`"
          label="url"
          :value="1"
          :for-value="`url-${extension}-${index}`"
          @click.stop="actions.openInputUrl()"
        ></input-radio>
        <input-radio
          v-model.number="state.fileType"
          :name="`file-${extension}-${index}`"
          label="file"
          :value="2"
          :for-value="`file-${extension}-${index}`"
        ></input-radio>
        <div class="name">
          <div>
            <span
              v-if="state.fileType === 0"
              class="sub-text-s2 text-gray-third"
              >업로드할 파일 유형을 선택해주세요.</span
            >
            <input
              v-if="state.fileType === 1"
              v-model="state.url"
              class="sub-text-s2"
              type="text"
              placeholder="링크 입력"
              style="width: 100%; height: fit-content"
            />
            <div style="display: flex; gap: 8px; align-items: center">
              <button
                v-if="state.fileType === 2"
                type="button"
                class="sub-text-s2"
                style="
                  height: fit-content;
                  padding: 2px;
                  border: 1px solid black;
                  min-width: fit-content;
                "
                @click="actions.openAttachmentSelector()"
              >
                파일 선택
              </button>
              <div v-if="state.fileSrc" class="sub-text-s2">
                {{ state.fileSrc }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button-basic
        class="plus-btn"
        bg-color="transparent"
        @action="actions.addAttachment()"
      >
        <template #icon>
          <plus-icon></plus-icon>
        </template>
      </button-basic>
      <input
        ref="attachmentSubtitleFileInput"
        type="file"
        :accept="`.${extension}`"
        hidden
        @change="actions.addSubtitleAttachment($event)"
      />
    </template>
  </div>
</template>

<script>
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import { reactive, computed, ref, onBeforeUpdate } from "vue";
import InputRadio from "@/components/console/inputs/InputRadio.vue";
import { dateFormat } from "@/helper/date";

export default {
  name: "AttachmentSubtitleFile",
  components: { InputRadio, PlusIcon, CloseIcon, ButtonBasic },
  props: {
    index: {
      type: Number,
      required: true,
    },
    showDeleteBtn: {
      type: Boolean,
      default: true,
    },
    attachment: {
      type: Object,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    extension: {
      type: String,
      default: "vtt",
    },
  },
  emits: ["popAttachment", "addSubtitleFile"],
  setup(props, { emit }) {
    const attachmentSubtitleFileInput = ref();

    const state = reactive({
      isAttachment: computed(() => {
        return !!props.attachment?.resourceId;
      }),
      isFile: false,
      file: null,
      fileSrc: "",
      isUrl: false,
      url: "",
      fileType: 0,
    });

    const createdAt = computed(() => {
      return state.isAttachment
        ? dateFormat(props.attachment.createdAt, "MM/DD HH:mm")
        : "-";
    });

    const actions = {
      deleteAttachment: () => {
        emit("popAttachment", props.attachment.resourceId);
      },
      addAttachment: () => {
        let data = {
          type: "",
          file: "",
        };
        if (state.fileType === 2) {
          data.type = "file";
          data.file = state.file;
          emit("addSubtitleFile", data);
        }
        if (state.fileType === 1) {
          data.type = "link";
          data.file = state.url;
          emit("addSubtitleFile", data);
        }
      },
      openInputUrl: () => {
        state.fileSrc = "";
        state.file = null;
      },
      openAttachmentSelector: () => {
        state.url = "";
        attachmentSubtitleFileInput.value.click();
      },
      addSubtitleAttachment: (e) => {
        const files = e.target.files || e.dataTransfer.files;
        // 선택된 파일 가져오기
        const selectedFile = files[0];
        state.file = files[0];
        state.fileSrc = selectedFile.name;
        // 파일을 읽기 위한 FileReader 객체 생성
        const reader = new FileReader();
        e.target.value = "";

        // 파일 읽기가 완료되었을 때의 이벤트 핸들러 등록
        reader.onload = function () {
          state.fileType === 2;
          state.url = "";
        };

        // 파일을 읽기
        reader.readAsDataURL(selectedFile);
      },
    };

    return { state, actions, attachmentSubtitleFileInput, createdAt };
  },
};
</script>

<style scoped>
.attachment-subtitle-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.info {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
}

.info .name {
  flex: 1;
  text-align: left;
}

.name a {
  text-decoration: none;
}

.plus-btn,
.close-btn {
  width: 10%;
  max-width: 48px;
  margin-left: auto;
}

.label {
  width: 60px;
  display: flex;
  justify-content: space-between;
}

.date {
  width: 90px;
}
</style>
