<template>
  <div class="mb-3">
    <div
      class="img-wrapper"
      :style="{
        backgroundImage: `url(${state.featuredImage})`,
      }"
    >
      <div class="button-wrapper">
        <button-basic
          shape="round"
          bg-color="#e6eaef"
          padding="12px 12px"
          @action="actions.openFileSelector()"
        >
          <template #icon>
            <edit-icon width="16" height="16" fill-color="#0D0D10"></edit-icon>
          </template>
        </button-basic>

        <button-basic
          v-if="deleteText"
          shape="round"
          bg-color="#e6eaef"
          padding="12px 12px"
          @action="actions.deleteImageFile()"
        >
          <template #icon>
            <delete-icon
              width="16"
              height="16"
              fill-color="#0D0D10"
            ></delete-icon>
          </template>
        </button-basic>
      </div>
      <div class="featured-file-overlay"></div>
    </div>

    <input
      ref="imageInput"
      type="file"
      hidden
      @change="actions.imageChange($event)"
    />
  </div>
</template>

<script>
import Helper from "@/helper";
import { computed, getCurrentInstance, reactive, ref } from "vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import FileStackService from "@/services/FileStackService";
import EditIcon from "@/components/console/icons/EditIcon.vue";
import DeleteIcon from "@/components/console/icons/DeleteIcon.vue";

export default {
  name: "InputImage",
  components: { DeleteIcon, EditIcon, ButtonBasic },
  props: {
    label: {
      type: String,
      required: false,
    },
    featuredImage: {
      type: String,
      default: "https://cdn.bigc.im/statics/commons/images/empty.png",
    },
    deleteText: {
      type: String,
      required: false,
    },
  },
  emits: ["updateData", "deleteImage"],
  setup(props, { emit }) {
    const imageInput = ref();
    const { proxy } = getCurrentInstance();
    const featuredImageAccepts = ["jpg", "jpeg", "png", "webp"];

    const state = reactive({
      featuredImage: computed(() => {
        const nullImage =
          "https://cdn.bigc.im/statics/commons/images/empty.png";
        return props.featuredImage ? props.featuredImage : nullImage;
      }),
    });

    const actions = {
      openFileSelector: () => {
        const fileStack = new FileStackService();

        console.log(fileStack);
        console.log(fileStack.options.fromSources);

        const mimeTypes = featuredImageAccepts.map((item) => {
          return proxy.$const.mimeTypes[item].value;
        });

        console.log(fileStack.options.fromSources);

        fileStack.options.accept = mimeTypes;
        fileStack.options.transformations.crop.aspectRatio = 16 / 9;

        fileStack.options.onFileUploadFinished = async (fileMetaData) => {
          emit("updateData", fileMetaData.url);
        };

        fileStack.open(fileStack.options);
      },
      imageChange: (e) => {
        const callback = (file, reader) => {
          emit("updateData", reader.result, file);
        };
        Helper.imageChange(e, callback);
      },
      deleteImageFile: () => {
        emit("deleteImage");
      },
    };

    return { state, actions, imageInput };
  },
};
</script>

<style scoped>
.img-wrapper {
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
}

.button-wrapper {
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  opacity: 0;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
}

.featured-file-overlay {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  border-radius: 9px;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
}

.img-wrapper:hover .button-wrapper {
  opacity: 1;
}

.img-wrapper:hover .featured-file-overlay {
  opacity: 1;
}

/* todo 컴포넌트 스타일 조정 필요. 스타일 컴포넌트 위의 부모에서 다시 사이즈를 재조정하게 됨. */
.button-wrapper button {
  width: fit-content;
}
</style>
