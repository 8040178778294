<template>
  <div class="input-select-small">
    <label v-if="label" class="sub-text-s2">
      {{ label }}
      <small v-if="subLabel">{{ subLabel }}</small>
      <span v-if="required" class="text-purple-50">*</span>
    </label>
    <select
      class="select-box__arrow sub-text-s2"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option v-if="placeholder" value="" disabled selected hidden>
        {{ placeholder }}
      </option>
      <option
        v-for="(item, index) in list"
        :key="`select-item-${index}`"
        :value="item.value"
      >
        {{ item.text }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "InputSelectSmall",
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    subLabel: {
      type: String,
      required: false,
    },
    list: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.input-select-small label,
select {
  display: block;
}

.input-select-small label {
  margin-bottom: 8px;
}

.input-select-small select {
  width: 100%;
  margin: 0;
  padding: 11px;
}

.select-box__arrow {
  border: 1px solid #e6eaef;
  border-radius: 4px;
  line-height: 18.2px;
  background-color: #ffffff;
  padding-left: 8px;
  margin-left: 8px;
}

.input-select-small select::-ms-expand {
  display: none;
}

.input-select-small select {
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #ffffff;
  background-image: url("/assets/images/svg/ic_check.svg");
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
</style>
