<template>
  <div class="input-text-box-new">
    <label v-if="label" class="label">
      <div>
        <span class="sub-text-s2">{{ label }}</span>
        <span v-if="required" class="text-purple-50">*</span>
      </div>
      <div v-if="subLabel" class="sub-label sub-text-s3 text-gray-second">
        <small>
          {{ subLabel }}
        </small>
      </div>
    </label>
    <textarea
      ref="textBox"
      class="textarea b-text-2"
      wrap="hard"
      :class="state.inputClass"
      :value="modelValue"
      :rows="rows"
      :max-rows="maxRows"
      :placeholder="placeholder"
      :maxlength="maxLength"
      @input="(e) => actions.updateText(e)"
    ></textarea>
    <div v-if="caption" class="caption">
      <small class="sub-text-s3 text-gray-third">{{ caption }}</small>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive, ref } from "vue";

export default {
  name: "InputTextBox",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
    subLabel: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
    },
    caption: {
      type: String,
      required: false,
    },
    // 한 줄 입력 또는 고정된 높이일 때 사용
    rows: {
      type: Number,
      required: false,
    },
    heightResize: {
      type: Boolean,
      default: true,
    },
    inputClass: {
      type: String,
      default: "",
      required: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    borderNone: {
      type: Boolean,
      default: true,
    },
    newlineDisabled: {
      type: Boolean,
      default: false,
    },
    maxRows: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const textBox = ref();

    const state = reactive({
      inputClass: `${props.inputClass} ${
        props.borderNone ? "border-0 px-0 py-0" : ""
      } ${props.heightResize ? "scrollbar-hide" : ""}`,
    });

    const heightResize = () => {
      textBox.value.style.height = "auto";

      let lineHeight = parseFloat(getComputedStyle(textBox.value).lineHeight);

      let paddingTopBottomWidth = Number(
        getComputedStyle(textBox.value).paddingTop.replace("px", "")
      );

      let borderWidth = Number(
        getComputedStyle(textBox.value).borderWidth.replace("px", "")
      );

      textBox.value.style.height = `${
        lineHeight + (paddingTopBottomWidth + borderWidth) * 2
      }px`;

      let line = Number(
        (
          (textBox.value.scrollHeight - paddingTopBottomWidth * 2) /
          lineHeight
        ).toFixed(1)
      );

      if (line === 1) {
        textBox.value.style.height = `${
          lineHeight + (paddingTopBottomWidth + borderWidth) * 2
        }px`;
      } else if (line > props.maxRows) {
        textBox.value.style.height = `${
          lineHeight * props.maxRows + (paddingTopBottomWidth + borderWidth) * 2
        }px`;
      } else {
        textBox.value.style.height = `${
          line * lineHeight + (paddingTopBottomWidth + borderWidth) * 2
        }px`;
      }
    };

    const actions = {
      updateText: (e) => {
        emit("update:modelValue", e.target.value);
        if (props.heightResize) {
          heightResize();
        }
      },
    };

    onMounted(() => {
      if (props.heightResize) {
        let lineHeight = parseFloat(getComputedStyle(textBox.value).lineHeight);

        let paddingTopBottomWidth = Number(
          getComputedStyle(textBox.value).paddingTop.replace("px", "")
        );

        let borderWidth = Number(
          getComputedStyle(textBox.value).borderWidth.replace("px", "")
        );

        let line = Number(
          (
            (textBox.value.scrollHeight - paddingTopBottomWidth * 2) /
            lineHeight
          ).toFixed(1)
        );

        textBox.value.style.height = `${
          lineHeight + (paddingTopBottomWidth + borderWidth) * 2
        }px`;

        if (line <= props.maxRows) {
          textBox.value.style.height = `${
            textBox.value.scrollHeight + borderWidth * 2
          }px`;
        } else {
          textBox.value.style.height = `${
            props.maxRows * lineHeight +
            (paddingTopBottomWidth + borderWidth) * 2
          }px`;
          textBox.value.style.overflowY = "scroll";
        }
      }

      // 줄바꿈 안되게 처리
      if (props.newlineDisabled) {
        textBox.value.addEventListener("keypress", (e) => {
          if (e.keyCode === 13) {
            e.preventDefault();
          }
        });
      }
    });

    onBeforeUnmount(() => {
      textBox.value.removeEventListener("keypress", (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      });
    });

    return { actions, textBox, state };
  },
};
</script>

<style scoped>
.label {
  display: block;
  margin-bottom: 4px;
}

.textarea {
  border: 1px solid #e6eaef;
  border-radius: 4px;
  width: 100%;
  resize: none;
  padding: 14px 16px;
  /*overflow-y: hidden;*/
}

.textarea:focus {
  border: 1px solid #9ea0a6;
}

.textarea::placeholder {
  color: #d0d5de !important;
}

.caption {
  display: flex;
  justify-content: flex-end;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.sub-label {
  margin-top: 4px;
}

.sub-label small {
  font-size: inherit;
}
</style>
