<template>
  <icon-base
    icon-name="copy-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3506 9.625H23.3193C25.1315 9.625 26.6006 11.0941 26.6006 12.9062V21.875C26.6006 23.808 25.0336 25.375 23.1006 25.375H14.3506C12.4176 25.375 10.8506 23.808 10.8506 21.875V13.125C10.8506 11.192 12.4176 9.625 14.3506 9.625ZM14.3506 11.375C13.3841 11.375 12.6006 12.1585 12.6006 13.125V21.875C12.6006 22.8415 13.3841 23.625 14.3506 23.625H23.1006C24.0671 23.625 24.8506 22.8415 24.8506 21.875V12.9062C24.8506 12.0606 24.165 11.375 23.3193 11.375H14.3506Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.375 7.875C4.375 5.942 5.942 4.375 7.875 4.375H16.625C18.558 4.375 20.125 5.942 20.125 7.875V9.625C20.125 10.1082 19.7332 10.5 19.25 10.5C18.7668 10.5 18.375 10.1082 18.375 9.625V7.875C18.375 6.9085 17.5915 6.125 16.625 6.125H7.875C6.9085 6.125 6.125 6.9085 6.125 7.875V16.625C6.125 17.5915 6.9085 18.375 7.875 18.375H11.375C11.8582 18.375 12.25 18.7668 12.25 19.25C12.25 19.7332 11.8582 20.125 11.375 20.125H7.875C5.942 20.125 4.375 18.558 4.375 16.625V7.875Z"
      :fill="fillColor"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
export default {
  name: "CopyIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 32 32",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
