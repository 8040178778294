<template>
  <base-modal-side
    :is-dimmed="true"
    style-class="contents-upload-modal"
    @closeModal="actions.closeModal"
  >
    <template #modalHeader>
      <h3 class="text-default">콘텐츠 세부 설정</h3>
      <p class="text-gray-second sub-text-s3">
        콘텐츠 저장을 원하시면 설정 이후에 변경사항 저장 버튼을 눌러주세요.
      </p>
    </template>

    <template #modalBody>
      <div v-if="type !== 'upload'">
        <embed-player
          v-if="
            !state.isGettingContentMeta &&
            state.contentForm.channelCode === 'youtube' &&
            state.contentForm.identifier
          "
          class="embed-player-area"
          :channel-code="state.contentForm.channelCode"
          :identifier="state.contentForm.identifier"
        ></embed-player>

        <input-basic-new
          label="URL"
          placeholder="ex ) https://www.youtube.com/watch?v=~~~"
          type="url"
          :default-value="state.contentForm.url"
          :disabled="state.isGettingContentMeta"
          :error-message="state.urlErrorMessage"
          @updateData="(value) => actions.updateContentFormData('url', value)"
        ></input-basic-new>

        <div>
          <div class="empty-meta">
            <div class="inner">
              <p class="text-gray-third sub-text-s3">
                유튜브나 vimeo 영상의 URL을 입력해주세요.<br />
                URL을 입력 후 아래 버튼을 누르면 영상 정보를 가져옵니다.
              </p>
              <button-basic
                text="링크에서 정보 가져오기"
                text-size="s2"
                padding="14px"
                @action="actions.getMetaFromUrl()"
              >
                링크에서 정보 가져오기
              </button-basic>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type === 'upload'" class="upload-form">
        <div>
          <div class="multipart-area">
            <div class="only-video">
              <div>
                <h5>영상 파일 업로드 (영상만 인코딩, mp4)</h5>
              </div>
              <div>
                <multipart-file-uploader
                  :name="'ncloud-only-video'"
                  :file-id="state.contentResourceId"
                  :trigger-route-leave="state.triggerRouteLeave"
                  :is-uploading="uploadingMultipart.isUploading"
                ></multipart-file-uploader>
              </div>
            </div>
            <div class="video-subtitle">
              <div>
                <h5>영상 파일 업로드 (자동 자막 생성,mp4)</h5>
              </div>
              <div>
                <multipart-file-uploader
                  :name="'ncloud-video-subtitles'"
                  :is-only-video-encoding="false"
                  :file-id="state.contentResourceId"
                  :trigger-route-leave="state.triggerRouteLeave"
                  :is-uploading="uploadingMultipart.isUploading"
                ></multipart-file-uploader>
              </div>
            </div>
          </div>

          <div v-if="uploadingMultipart.isUploading" class="progress-area">
            <div class="file-label">
              <p class="sub-text-s2 text-gray-second">
                파일명 : {{ uploadingMultipart.fileName }}
              </p>
              <p class="sub-text-s2 text-gray-second">
                확장자 : {{ uploadingMultipart.fileFormat }}
              </p>
            </div>
            <div class="file-progress">
              <div class="progress">
                <div class="sub-text-s3 text-blue-50 progress-status">
                  파일 업로드 {{ progress }} %
                </div>
                <div class="bg-gray-010 progress-bar">
                  <div
                    :style="{ width: `${progress}%` }"
                    class="bg-blue-50"
                  ></div>
                </div>
              </div>
              <div class="actions">
                <button-basic
                  class="close-btn"
                  bg-color="transparent"
                  text="취소"
                  text-size="s3"
                  color="#818287"
                  padding="10px"
                  :disabled="uploadingMultipart.isUploadSuccess"
                  @action="actions.cancelMultipartUpload()"
                ></button-basic>
              </div>
            </div>
          </div>
        </div>

        <hr class="hr-third" />

        <div class="url-input-form">
          <div>
            <h4>업로드 파일 url 정보</h4>
          </div>
          <div>
            <input-basic-new
              class="input-area"
              label="drm-cid"
              :default-value="state.contentForm.drmCid"
              @updateData="
                (value) => actions.updateContentFormData('drmCid', value)
              "
            ></input-basic-new>
          </div>
          <div class="header">
            <h5>동영상 파일 주소(웹용)</h5>
            <button-text
              text-size="s2"
              text="URL-dash 복사"
              padding="0 14px"
              text-weight="text"
              :is-icon="true"
              icon-position="back"
              color="#818287"
              @click="
                () => {
                  actions.copyUrl(state.contentForm.urlDash);
                }
              "
            >
              <template #icon>
                <copy-icon fill-color="#0D0D10"></copy-icon>
              </template>
            </button-text>
            <button-text
              text-size="s2"
              text="URL-hls 복사"
              padding="0 14px"
              text-weight="text"
              :is-icon="true"
              icon-position="back"
              color="#818287"
              @click="
                () => {
                  actions.copyUrl(state.contentForm.urlHls);
                }
              "
            >
              <template #icon>
                <copy-icon fill-color="#0D0D10"></copy-icon>
              </template>
            </button-text>
          </div>
          <div>
            <input-basic-new
              class="input-area"
              label="URL-dash"
              :default-value="state.contentForm.urlDash"
              :error-message="
                urlDashHlsValidationStatus.urlDash
                  ? ''
                  : 'url 형식이 맞지 않아요.'
              "
              @updateData="
                (value) => actions.updateContentFormData('urlDash', value)
              "
            ></input-basic-new>
            <input-basic-new
              class="input-area"
              label="URL-hls"
              :default-value="state.contentForm.urlHls"
              :error-message="
                urlDashHlsValidationStatus.urlHls
                  ? ''
                  : 'url 형식이 맞지 않아요.'
              "
              @updateData="
                (value) => actions.updateContentFormData('urlHls', value)
              "
            ></input-basic-new>
          </div>
          <div class="header">
            <h5>동영상 & 자막 파일 주소(앱용)</h5>
            <button-text
              text-size="s2"
              text="URL-dash-subtitle 복사"
              padding="0 14px"
              text-weight="text"
              :is-icon="true"
              icon-position="back"
              color="#818287"
              @click="
                () => {
                  actions.copyUrl(state.contentForm.urlDashSubtitles);
                }
              "
            >
              <template #icon>
                <copy-icon fill-color="#0D0D10"></copy-icon>
              </template>
            </button-text>
            <button-text
              text-size="s2"
              text="URL-hls-subtitle 복사"
              padding="0 14px"
              text-weight="text"
              :is-icon="true"
              icon-position="back"
              color="#818287"
              @click="
                () => {
                  actions.copyUrl(state.contentForm.urlHlsSubtitles);
                }
              "
            >
              <template #icon>
                <copy-icon fill-color="#0D0D10"></copy-icon>
              </template>
            </button-text>
          </div>
          <div>
            <input-basic-new
              class="input-area"
              label="URL-dash-subtitle"
              :default-value="state.contentForm.urlDashSubtitles"
              :error-message="
                urlDashHlsValidationStatus.urlDashSubtitles
                  ? ''
                  : 'url 형식이 맞지 않아요.'
              "
              @updateData="
                (value) =>
                  actions.updateContentFormData('urlDashSubtitles', value)
              "
            ></input-basic-new>
            <input-basic-new
              class="input-area"
              label="URL-hls-subtitle"
              :default-value="state.contentForm.urlHlsSubtitles"
              :error-message="
                urlDashHlsValidationStatus.urlHlsSubtitles
                  ? ''
                  : 'url 형식이 맞지 않아요.'
              "
              @updateData="
                (value) =>
                  actions.updateContentFormData('urlHlsSubtitles', value)
              "
            ></input-basic-new>
          </div>
        </div>
        <div class="subtitle-lang-form">
          <div class="header">
            <div>
              <h5>자막언어</h5>
              <button-text
                text-size="s2"
                text="자막 추가하기"
                padding="0 14px"
                text-weight="text"
                :is-icon="true"
                icon-position="front"
                color="#818287"
                @click="actions.scrollIntoCaptionFormElement"
              >
                <template #icon>
                  <plus-icon
                    width="12"
                    height="12"
                    fill-color="#0D0D10"
                  ></plus-icon>
                </template>
              </button-text>
            </div>
            <div>
              <span class="b-text-2 text-gray-second">
                {{ supportedSubtitles.text }}
              </span>
              <span class="b-text-2 text-warning">
                {{ supportedSubtitles.message }}
              </span>
            </div>
          </div>
          <ul v-if="supportedSubtitles.arr" class="buttons">
            <li
              v-for="item in supportedSubtitles.arr"
              :key="item"
              class="bg-gray-studio"
            >
              <button @click="actions.popSubTitles(item)">
                <span class="sub-title-s2"> {{ item }} </span>
                <close-icon fill-color="#818287"></close-icon>
              </button>
            </li>
          </ul>
        </div>

        <hr class="hr-third" />

        <div v-if="content" ref="cpationFormElment" class="caption-form">
          <div>
            <h5>vtt 자막파일 추가</h5>
          </div>
          <div>
            <div class="caption-th">
              <span class="sub-text-s2 text-gray-second lang">언어코드 </span>
              <span class="sub-text-s2 text-gray-second name">파일명</span>
              <span class="sub-text-s2 text-gray-second date">생성일</span>
            </div>
            <ul class="subtitle-file-list">
              <li v-for="(item, index) in vttCaptionFiles" :key="index">
                <attachment-subtitle-file
                  :index="index"
                  :code="item.id"
                  :extension="item.extension"
                  :attachment="item.caption"
                  :disable="state.addCaptionLoading"
                  @add-subtitle-file="
                    (data) => actions.addSubtitleAttachment(item.id, data)
                  "
                  @pop-attachment="
                    (resourceId) => actions.deleteCaption(resourceId)
                  "
                ></attachment-subtitle-file>
              </li>
            </ul>
          </div>
        </div>

        <hr class="hr-third" />

        <div class="caption-form">
          <div>
            <h5>srt 자막파일 추가</h5>
          </div>
          <div>
            <div class="caption-th" style="display: flex; gap: 16px">
              <span class="sub-text-s2 text-gray-second lang">언어코드 </span>
              <span class="sub-text-s2 text-gray-second name">파일명</span>
              <span class="sub-text-s2 text-gray-second date">생성일</span>
            </div>
            <ul class="subtitle-file-list">
              <li v-for="(item, index) in srtCaptionFiles" :key="index">
                <attachment-subtitle-file
                  :index="index"
                  :code="item.id"
                  :extension="item.extension"
                  :attachment="item.caption"
                  :disable="state.addCaptionLoading"
                  @add-subtitle-file="
                    (data) => actions.addSubtitleAttachment(item.id, data)
                  "
                  @pop-attachment="
                    (resourceId) => actions.deleteCaption(resourceId)
                  "
                ></attachment-subtitle-file>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>

    <template #modalFooter>
      <div class="btn-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          color="#0d0d0d"
          bg-color="#E6EAEF"
          @action="actions.closeModal"
        ></button-basic>
        <button-basic
          class="save"
          text="설정"
          @action="actions.updateContentForm"
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import BaseModalSide from "@/components/common/BaseModalSide.vue";
import { computed, onBeforeMount, reactive, ref } from "vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import EmbedPlayer from "@/components/console/EmbedPlayer/EmbedPlayer.vue";
import swal from "@/helper/swal";
import { useStore } from "vuex";
import MultipartFileUploader from "@/components/console/files/MultipartFileUploader.vue";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";
import AttachmentSubtitleFile from "@/components/console/files/AttachmentSubtitleFile.vue";
import ApiService from "@/api";
import { cloneDeep } from "lodash";
import CopyIcon from "@/components/console/icons/CopyIcon.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import helper from "@/helper";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import { validateDashHlsUrl } from "@/helper/content";

export default {
  name: "ContentSettingModal",
  components: {
    PlusIcon,
    CloseIcon,
    ButtonText,
    CopyIcon,
    AttachmentSubtitleFile,
    InputBasicNew,
    MultipartFileUploader,
    EmbedPlayer,
    ButtonBasic,
    BaseModalSide,
  },
  props: {
    content: {
      type: Object,
      required: false,
    },
    resourceId: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "upload",
    },
  },
  emits: ["hideModal", "saveComplete", "updateForm"],
  setup(props, { emit }) {
    const store = useStore();

    const cpationFormElment = ref(null);

    const state = reactive({
      contentForm: {
        type: props.content ? props.content.type : 1,
        channelCode: props.content ? props.content.channelCode : "",
        identifier: props.content ? props.content.identifier : "",
        url: props.content ? props.content.url : "",
        urlDash: props.content ? props.content.urlDash : "",
        urlDashSubtitles: props.content ? props.content.urlDashSubtitles : "",
        urlHls: props.content ? props.content.urlHls : "",
        urlHlsSubtitles: props.content ? props.content.urlHlsSubtitles : "",
        drmCid: props.content ? props.content.drmCid : "",
        title: props.content ? props.content.title : "",
        description: props.content ? props.content.description : "",
        text: props.content ? props.content.text : "",
        featuredImage: props.content ? props.content.featuredImage : "",
        publishedAt: props.content?.publishedAt
          ? props.content.publishedAt
          : "",
        isPaid: props.content ? props.content.isPaid : false,
        urlErrorMessage: "",
        captions: props.content ? props.content.captions : [],
      },
      successGetMetaFromUrl: false,
      addCaptionLoading: false,
      contentResourceId: null,
      isGettingContentMeta: false,
    });

    onBeforeMount(() => {
      state.contentResourceId = props.resourceId;
    });

    const captions = computed(() => {
      return props.content.captions; // attachment 자체
    });

    const vttCaptionFiles = computed(() => {
      const arr = [
        { id: "ko", extension: "vtt", caption: {} },
        { id: "en", extension: "vtt", caption: {} },
        { id: "ja", extension: "vtt", caption: {} },
        { id: "zh_cn", extension: "vtt", caption: {} },
        { id: "zh_tw", extension: "vtt", caption: {} },
        { id: "es", extension: "vtt", caption: {} },
        { id: "vi", extension: "vtt", caption: {} },
        { id: "id", extension: "vtt", caption: {} },
        { id: "th", extension: "vtt", caption: {} },
      ];

      captions.value.forEach((item) => {
        if (item.extension === "vtt") {
          switch (item.lang) {
            case "ko":
              setCaptionFile(item, arr, 0, "한국");
              break;
            case "en":
              setCaptionFile(item, arr, 1, "영어");
              break;
            case "ja":
              setCaptionFile(item, arr, 2, "일본어");
              break;
            case "zh_cn":
              setCaptionFile(item, arr, 3, "중국어 간체");
              break;
            case "zh_tw":
              setCaptionFile(item, arr, 4, "중국어 번체");
              break;
            case "es":
              setCaptionFile(item, arr, 5, "스페인어");
              break;
            case "vi":
              setCaptionFile(item, arr, 6, "베트남어");
              break;
            case "id":
              setCaptionFile(item, arr, 7, "인도네시아어");
              break;
            case "th":
              setCaptionFile(item, arr, 8, "태국어");
              break;
            default:
              arr[arr.length].id = item.lang;
              arr[arr.length].caption = item;
              break;
          }
        }
      });

      return arr;
    });

    const srtCaptionFiles = computed(() => {
      const arr = [
        { id: "ko", extension: "srt", caption: {} },
        { id: "en", extension: "srt", caption: {} },
        { id: "ja", extension: "srt", caption: {} },
        { id: "zh_cn", extension: "srt", caption: {} },
        { id: "zh_tw", extension: "srt", caption: {} },
        { id: "es", extension: "srt", caption: {} },
        { id: "vi", extension: "srt", caption: {} },
        { id: "id", extension: "srt", caption: {} },
        { id: "th", extension: "srt", caption: {} },
      ];

      captions.value.forEach((item) => {
        // filter 고려
        if (item.extension === "srt") {
          switch (item.lang) {
            case "ko":
              setCaptionFile(item, arr, 0, "한국");
              break;
            case "en":
              setCaptionFile(item, arr, 1, "영어");
              break;
            case "ja":
              setCaptionFile(item, arr, 2, "일본어");
              break;
            case "zh_cn":
              setCaptionFile(item, arr, 3, "중국어 간체");
              break;
            case "zh_tw":
              setCaptionFile(item, arr, 4, "중국어 번체");
              break;
            case "es":
              setCaptionFile(item, arr, 5, "스페인어");
              break;
            case "vi":
              setCaptionFile(item, arr, 6, "베트남어");
              break;
            case "id":
              setCaptionFile(item, arr, 7, "인도네시아어");
              break;
            case "th":
              setCaptionFile(item, arr, 8, "태국어");
              break;
            default:
              arr[arr.length].id = item.lang;
              arr[arr.length].caption = item;
              break;
          }
        }
      });

      return arr;
    });

    const countVttCaptionFiles = computed(() => {
      let captionlist = captions.value;

      let numberOfVttCaptions = 0;

      for (let i = 0; i < captionlist.length; i++) {
        if (captionlist[i].extension === "vtt") {
          ++numberOfVttCaptions;
        }
      }

      return numberOfVttCaptions;
    });

    const supportedSubtitles = computed(() => {
      let urlDashSubtitle = state.contentForm.urlDashSubtitles;
      let urlHlsSubtitle = state.contentForm.urlHlsSubtitles;

      const result = {
        message: "",
        text: "",
        arr: [],
      };

      // url-hls-Subtitle, url-dash-Subtitle 값이 있다.
      if (urlDashSubtitle && urlHlsSubtitle) {
        let dashArr = getSupportedLangFromUrlSubtitle(urlDashSubtitle);
        let hlsArr = getSupportedLangFromUrlSubtitle(urlHlsSubtitle);

        let dashArrLength = dashArr.length;
        let hlsArrLength = hlsArr.length;

        const case0 = dashArrLength > 0 && hlsArrLength > 0;
        const case4 = dashArrLength > 0 || hlsArrLength > 0;

        // url-hls-Subtitle에 url-dash-Subtitle 자막 정보가 있다.
        if (case0) {
          const case1 = dashArrLength === hlsArrLength;
          // url-hls-Subtitle, url-dash-Subtitle 두개의 자막 정보가 같다.
          if (case1) {
            result.text =
              "지원하는 언어의 언어코드 입니다. 클릭시 자막을 삭제하실 수 있습니다.";
            result.arr = dashArr;
          }

          // url-hls-Subtitle, url-dash-Subtitle 두개의 자막 정보가 같지 않다.
          if (!case1) {
            result.arr = dashArr;
            result.message = "dash와 hls의 자막 입력이 다릅니다.";
          }

          const case2 = countVttCaptionFiles.value === 0;
          // vtt 자막 파일이 없다.
          if (case2) {
            result.message =
              "자막 파일 주소와 등록된 자막 파일이 일치하지 않습니다.";
          } else {
            // vtt 자막 파일이 있다.
            const case3 =
              dashArrLength !== countVttCaptionFiles.value ||
              hlsArrLength !== countVttCaptionFiles.value;
            // 자막 파일이 있으나, 자막 파일과 url-hls-Subtitle, url-dash-Subtitle의 자막 정보가 다르다.
            if (case3) {
              result.message =
                "자막 파일 주소와 등록된 자막 파일이 일치하지 않습니다.";
            }
          }
          // url-hls-Subtitle, url-dash-Subtitle 두개의 자막 정보가 다르다.
        } else if (case4) {
          // 하나라도 없거나
          result.text = "등록된 자막이 없습니다.";
          result.message = "dash와 hls의 자막 입력이 다릅니다.";
        } else {
          // url-hls-Subtitle, url-dash-Subtitle 자막 정보가 없다.
          result.text = "등록된 자막이 없습니다.";
          const case5 = countVttCaptionFiles.value;
          // url-hls-Subtitle, url-dash-Subtitle 자막 정보는 없으나, vtt 자막 파일은 존재한다.
          if (case5) {
            result.message =
              "자막 파일 주소와 등록된 자막 파일이 일치하지 않습니다.";
          }
        }
        // url-hls-Subtitle, url-dash-Subtitle 값이 없다.
      } else {
        result.arr = undefined;
        result.text = "등록된 자막이 없습니다.";
      }
      return result;
    });

    const uploadingMultipart = computed(() => {
      let multipartFiles = store.getters["contents/multipartFiles"];
      let isUploadingData = multipartFiles.length > 0;
      if (isUploadingData) {
        const index = multipartFiles.findIndex((item) => {
          return item.resourceId === state.contentResourceId;
        });
        return multipartFiles[index];
      } else {
        return { isUploading: false };
      }
    });

    const progress = computed(() => {
      if (uploadingMultipart.value.isUploading) {
        let denominator = Number(uploadingMultipart.value.numberOfFiles);
        let numerato = Number(uploadingMultipart.value.progress);
        let rates = ((numerato / denominator) * 100).toFixed(2);
        return rates;
      } else {
        return 0;
      }
    });

    const urlDashHlsValidationStatus = computed(() => {
      const isUrlDash =
        state.contentForm.urlDash !== null &&
        state.contentForm.urlDash.length > 0;
      const isUrlHls =
        state.contentForm.urlHls !== null &&
        state.contentForm.urlHls.length > 0;
      const isUrlDashSubtitle =
        state.contentForm.urlDashSubtitles !== null &&
        state.contentForm.urlDashSubtitles.length > 0;
      const isUrlHlsSubtitle =
        state.contentForm.urlHlsSubtitles !== null &&
        state.contentForm.urlHlsSubtitles.length > 0;

      // url 이 있고, validateDash가 true인 경우

      const validationStatus = {
        urlDash: true,
        urlHls: true,
        urlDashSubtitles: true,
        urlHlsSubtitles: true,
      };

      if (isUrlDash) {
        validationStatus.urlDash = validateDashHlsUrl(
          "dash",
          state.contentForm.urlDash
        );
      }

      if (isUrlHls) {
        validationStatus.urlHls = validateDashHlsUrl(
          "hls",
          state.contentForm.urlHls
        );
      }

      if (isUrlDashSubtitle) {
        validationStatus.urlDashSubtitles = validateDashHlsUrl(
          "dash",
          state.contentForm.urlDashSubtitles
        );
      }

      if (isUrlHlsSubtitle) {
        validationStatus.urlHlsSubtitles = validateDashHlsUrl(
          "hls",
          state.contentForm.urlHlsSubtitles
        );
      }

      return validationStatus;
    });

    const setCaptionFile = (item, arr, index) => {
      // 자막파일이 기존에 존재하는 경우, 마지막 자막으로 배치
      if (arr[index].caption?.resourceId) {
        arr[arr.length] = {
          id: item.lang,
          extension: item.extension,
          caption: item,
        };
      } else {
        arr[index].caption = item;
      }
    };

    const getSupportedLangFromUrlSubtitle = (urlSubtitle) => {
      let splitStr = urlSubtitle.split(",");

      let filteredStr = splitStr.filter((item) => item.includes("lang"));

      let splitTranslatedStr = filteredStr.map((item) =>
        item.split("translated_")
      );

      let result = splitTranslatedStr.map((item, index) => {
        const indexIncludeVttStr = item.findIndex((element) =>
          element.includes(".vtt")
        );

        return item[indexIncludeVttStr].replace(".vtt", "");
      });

      return result;
    };

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      addSubtitleAttachment: (lang, data) => {
        const formData = new FormData();
        data.type === "file"
          ? formData.append("file", data.file)
          : formData.append("fileUrl", data.file);
        formData.append("lang", lang);

        let payload = {
          resourceId: state.contentResourceId,
          file: formData,
        };

        store.dispatch("contents/uploadContentCaption", payload).then(() => {
          swal.createCompleteToast();
          // todo 수정 필요

          const { urlHlsSubtitles, urlDashSubtitles } =
            store.getters["contents/content"];

          state.contentForm.urlHlsSubtitles = urlHlsSubtitles;
          state.contentForm.urlDashSubtitles = urlDashSubtitles;
        });
      },
      deleteCaption: (captionResourceId) => {
        let payload = {
          resourceId: captionResourceId,
        };
        store.dispatch("contents/deleteContentCaption", payload).then(() => {
          swal.deleteCompleteToast();
          // todo 수정 필요
          const { urlHlsSubtitles, urlDashSubtitles } =
            store.getters["contents/content"];
          state.contentForm.urlHlsSubtitles = urlHlsSubtitles;
          state.contentForm.urlDashSubtitles = urlDashSubtitles;
        });
      },
      updateContentFormData: (key, value) => {
        state.contentForm[`${key}`] = value;
      },
      cancelMultipartUpload: () => {
        let resourceId = uploadingMultipart.value.resourceId;
        store.dispatch("contents/cancelUploadingMultipart", resourceId);
      },
      popSubTitles: (value) => {
        const isCaption = captions.value.length > 0;
        if (isCaption) {
          let lang = value.toLowerCase().replace("-", "_");
          let caption = captions.value.find((item) => {
            return item.lang === lang && item.extension === "vtt";
          });
          actions.deleteCaption(caption.resourceId);
        } else {
          swal.errorToast("해당 자막 파일이 존재 하지 않습니다.");
        }
      },
      copyUrl: (value) => {
        const url = value;
        helper.copyToClipboard(url);
      },
      scrollIntoCaptionFormElement: () => {
        cpationFormElment.value.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      },
      getMetaFromUrl: async () => {
        state.isGettingContentMeta = true;
        await ApiService.getContentMetaFromUrl({ url: state.contentForm.url })
          .then((res) => {
            if (res.data.success) {
              state.urlErrorMessage = "";
              state.contentForm = { ...state.contentForm, ...res.data.data };
              state.successGetMetaFromUrl = true;
              setTimeout(() => {
                state.thumbnailImage = res.data.data.featuredImage;
                state.contentForm.featuredImage = res.data.data.featuredImage;
              }, 10);
            } else {
              state.urlErrorMessage = "유효하지않은 URL 입니다.";
              state.successGetMetaFromUrl = false;
            }
          })
          .catch((e) => {
            console.log(e);
            state.urlErrorMessage = "유효하지않은 URL 입니다.";
            state.successGetMetaFromUrl = false;
          });
        state.isGettingContentMeta = false;
      },
      updateContentForm: () => {
        const form = cloneDeep(state.contentForm);
        emit("updateForm", form);
      },
    };

    return {
      state,
      actions,
      vttCaptionFiles,
      srtCaptionFiles,
      uploadingMultipart,
      progress,
      cpationFormElment,
      urlDashHlsValidationStatus,
      supportedSubtitles,
    };
  },
};
</script>

<style scoped src="./style.css"></style>
